import { render, staticRenderFns } from "./AudioControls.vue?vue&type=template&id=3198f2db&scoped=true"
import script from "./AudioControls.vue?vue&type=script&lang=js"
export * from "./AudioControls.vue?vue&type=script&lang=js"
import style0 from "./AudioControls.vue?vue&type=style&index=0&id=3198f2db&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3198f2db",
  null
  
)

export default component.exports