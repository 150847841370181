<template>
  <div id="app" :class="{ 'is-playing': isPreviewPlaying }">
    <div class="background-animation"></div>
    <el-container>
      <el-header>
        <div class="header-content">
          <div class="header-left">
            <h1>Mixr</h1>
            <div class="header-subtitle">Online Audio Track Mixer for Content Creators</div>
          </div>
        </div>
      </el-header>

      <el-main>
        <!-- Audio Synthesizer -->
        <AudioSynthesizer
          ref="synthesizer"
          :audioTracks="audioTracks"
          :currentTime="currentTime"
          :isPlaying="isPreviewPlaying"
          :playOrder="playOrder"
          @add-track="handleAddTrack"
          @remove-track="removeTrack"
          @update-track="updateTrack"
          @tracks-change="handleTracksChange"
          @synthesize="handleSynthesize"
        />

        <!-- Global Controls -->
        <AudioControls
          :hasAnyTracks="hasAnyTracks"
          :hasMainTracks="Boolean($refs.synthesizer?.mainTracks?.length)"
          :synthesizedBuffer="synthesizedBuffer"
          :isSynthesizing="isSynthesizing"
          :isPlaying="isPreviewPlaying"
          :currentTime="currentTime"
          :duration="duration"
          :exportFormat="exportFormat"
          @update:duration="updateDuration"
          @update:export-format="updateExportFormat"
          @update:play-order="updatePlayOrder"
          @synthesize="handleSynthesize"
          @preview="handlePreview"
          @export="handleExport"
        />

        <!-- Audio Visualizer -->
        <AudioVisualizer
          :isPlaying="isPreviewPlaying"
          :analyserNode="analyserNode"
          :currentTime="currentTime"
          :duration="duration"
          @seek="handleSeek"
        />

        <!-- 合成进度遮罩层 -->
        <div v-if="showSynthesisProgress" class="synthesis-overlay">
          <div class="synthesis-modal">
            <div class="synthesis-content">
              <h3>Synthesizing Audio...</h3>
              <div class="progress-bar">
                <div class="progress-fill" :style="{ width: synthesisProgress + '%' }"></div>
              </div>
              <p>{{ synthesisMessage }}</p>
            </div>
          </div>
        </div>

        <!-- 导出进度遮罩层 -->
        <div v-if="showExportProgress" class="export-overlay">
          <div class="export-modal">
            <div class="export-content">
              <div class="export-icon">
                <div class="circle-loader">
                  <div class="circle"></div>
                  <div class="circle"></div>
                  <div class="circle"></div>
                </div>
              </div>
              <h3>Exporting Audio...</h3>
              <p class="export-message">{{ exportMessage }}</p>
              <div class="warning-message">
                <i class="el-icon-warning"></i>
                Please keep this page open until the export is complete
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>

    <div class="user-guide">
      <div class="guide-content">
        <h2>Welcome to Mixr</h2>
        
        <div class="guide-section">
          <h3>About Mixr</h3>
          <p>Mixr is a powerful online audio mixing tool designed for content creators, YouTubers, podcasters, and anyone who needs quick and professional audio mixing. No software installation required - just upload, mix, and export.</p>
        </div>
        
        <div class="guide-section">
          <h3>Features</h3>
          <ul>
            <li><strong>Main Tracks:</strong> Perfect for primary content like voice-overs or main music themes. These tracks play in sequence and loop seamlessly.</li>
            <li><strong>Auxiliary Tracks:</strong> Ideal for background music, ambient sounds, or sound effects. These loop independently throughout your mix.</li>
            <li><strong>Visual Effects:</strong> Real-time audio visualization helps you monitor your mix with style.</li>
            <li><strong>Easy Export:</strong> Download your finished mix in high-quality audio formats.</li>
          </ul>
        </div>

        <div class="guide-section">
          <h3>Quick Start Guide</h3>
          <ul>
            <li>1. Set your desired output duration (up to 1 hour)</li>
            <li>2. Upload your audio files to Main or Auxiliary tracks</li>
            <li>3. Adjust individual track volumes as needed</li>
            <li>4. Click "Synthesize" to process your mix</li>
            <li>5. Preview the result with our visualizer</li>
            <li>6. Export your finished mix</li>
          </ul>
        </div>

        <div class="guide-section">
          <h3>Perfect For</h3>
          <ul>
            <li>YouTube background music</li>
            <li>Podcast intros and background tracks</li>
            <li>Social media content</li>
            <li>Streaming overlays</li>
            <li>Quick audio demos</li>
          </ul>
        </div>

        <div class="guide-section">
          <h3>Technical Notes</h3>
          <ul>
            <li>Supports MP3 and WAV formats</li>
            <li>Maximum output duration: 1 hour</li>
            <li>Browser-based processing - no downloads needed</li>
            <li>Please keep the page open during processing</li>
            <li>All processing happens locally in your browser</li>
            <li>High-quality MP3 export (192kbps stereo)</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioSynthesizer from './components/AudioSynthesizer.vue'
import AudioControls from './components/AudioControls.vue'
import AudioVisualizer from './components/AudioVisualizer.vue'
import WaveSurfer from 'wavesurfer.js'

export default {
  name: 'App',
  
  components: {
    AudioSynthesizer,
    AudioControls,
    AudioVisualizer
  },
  
  data() {
    return {
      audioTracks: [],
      synthesizedBuffer: null,
      isSynthesizing: false,
      isPreviewPlaying: false,
      currentTime: 0,
      duration: 120,
      exportFormat: 'mp3',
      audioContext: null,
      previewSource: null,
      previewStartTime: 0,
      rafId: null,
      analyserNode: null,
      showSynthesisProgress: false,
      synthesisProgress: 0,
      synthesisMessage: '',
      synthesisTimer: null,
      showExportProgress: false,
      exportMessage: '',
      exportMessages: [
        'Preparing audio data...',
        'Processing audio format...',
        'Generating file...',
        'Almost done...'
      ],
      exportInterval: null,
      playOrder: 'sequential'
    }
  },

  computed: {
    hasAnyTracks() {
      return this.$refs.synthesizer?.mainTracks?.length > 0 || 
             this.$refs.synthesizer?.auxTracks?.length > 0
    }
  },

  methods: {
    handleTracksChange({ mainTracksCount }) {
      this.synthesizedBuffer = null
      this.$forceUpdate()
    },

    handleAddTrack(track) {
      this.synthesizedBuffer = null
    },

    removeTrack(trackId) {
      this.synthesizedBuffer = null // Reset synthesized buffer when track is removed
    },

    updateTrack(trackId, track) {
      this.synthesizedBuffer = null // Reset synthesized buffer when track is updated
    },

    updateDuration(value) {
      this.duration = value
    },

    updateExportFormat(value) {
      this.exportFormat = value
    },

    async handleSynthesize() {
      if (this.isSynthesizing) return
      this.isSynthesizing = true
      this.startSynthesisProgress()

      try {
        const synthesizer = this.$refs.synthesizer
        const allTracks = [
          ...synthesizer.mainTracks,
          ...synthesizer.auxTracks
        ]

        this.synthesizedBuffer = await this.processMainAudioSequence(allTracks)
        
        // 更新时间轴信息
        synthesizer.updateTimelines(this.duration)
        
        this.$message.success('Audio synthesis completed')
      } catch (error) {
        console.error('Synthesis error:', error)
        this.$message.error('Failed to synthesize audio')
      } finally {
        this.isSynthesizing = false
        setTimeout(() => {
          this.stopSynthesisProgress()
        }, 1000)
      }
    },

    async synthesizeAudio() {
      if (!this.$refs.synthesizer) return
      
      const ctx = await this.initAudioContext()
      
      // 创建离线音频上下文
      const offlineCtx = new OfflineAudioContext({
        numberOfChannels: 2,
        length: 44100 * this.duration,
        sampleRate: 44100
      })
      
      // 处理主音轨和辅助音轨
      await this.processMainAudioSequence(offlineCtx, this.duration)
      await this.processAuxAudioSequence(offlineCtx, this.duration)
      
      // 开始渲染
      const renderedBuffer = await offlineCtx.startRendering()
      
      // 保存合成结果
      this.synthesizedBuffer = renderedBuffer
      
      return renderedBuffer
    },

    async handlePreview() {
      if (!this.synthesizedBuffer) return
      
      if (this.isPreviewPlaying) {
        // 停止播放
        this.stopPreview()
        return
      }
      
      // 创建的音频源
      this.previewSource = this.audioContext.createBufferSource()
      this.previewSource.buffer = this.synthesizedBuffer
      
      // 创建增益节点
      const gainNode = this.audioContext.createGain()
      gainNode.gain.value = 1
      
      // 创建分析器节点
      this.analyserNode = this.audioContext.createAnalyser()
      
      // 连接节点
      this.previewSource.connect(gainNode)
      gainNode.connect(this.analyserNode)
      this.analyserNode.connect(this.audioContext.destination)
      
      // 从当前时间开始播放
      this.previewStartTime = this.audioContext.currentTime - this.currentTime
      this.previewSource.start(0, this.currentTime)
      
      // 设置播放状态
      this.isPreviewPlaying = true
      
      // 立即更新一次时间
      this.currentTime = this.audioContext.currentTime - this.previewStartTime
      
      // 开始更新时间
      this.updateTime()
      
      // 添加结束事件监听
      this.previewSource.onended = () => {
        this.stopPreview()
      }
    },

    stopPreview() {
      if (this.previewSource) {
        this.previewSource.stop()
        this.previewSource.disconnect()
        this.previewSource = null
      }
      if (this.rafId) {
        cancelAnimationFrame(this.rafId)
        this.rafId = null
      }
      this.isPreviewPlaying = false
      this.currentTime = 0
      this.analyserNode = null
      this.previewStartTime = 0
    },

    async handleExport() {
      if (!this.synthesizedBuffer) return
      
      // 立即显示导出提示
      this.showExportProgress = true
      this.startExportProgress()
      
      // 如果正在播放，先停止预览
      if (this.isPreviewPlaying) {
        this.stopPreview()
      }
      
      // 延迟500ms再开始处理，确保遮罩已显示
      await new Promise(resolve => setTimeout(resolve, 500))
      
      try {
        const audioBlob = await this.encodeAudioBuffer(
          this.synthesizedBuffer,
          this.exportFormat
        )
        
        // Create download link
        const url = URL.createObjectURL(audioBlob)
        const a = document.createElement('a')
        a.href = url
        a.download = `exported_audio.${this.exportFormat}`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(url)

        // 等待一小段时间后关闭遮罩，确保文件选择对话框已经显示
        setTimeout(() => {
          if (this.exportInterval) {
            clearInterval(this.exportInterval)
            this.exportInterval = null
          }
          this.showExportProgress = false
          this.$message.success('Audio exported successfully')
        }, 500)
      } catch (error) {
        console.error('Export error:', error)
        if (this.exportInterval) {
          clearInterval(this.exportInterval)
          this.exportInterval = null
        }
        this.showExportProgress = false
        this.$message.error('Export failed')
      }
    },

    async processMainAudioSequence(tracks) {
      // 使用用户设置的时长
      const sampleRate = 44100
      const ctx = new OfflineAudioContext(2, this.duration * sampleRate, sampleRate)
      
      try {
        // Create master gain node first
        const masterGain = ctx.createGain()
        masterGain.connect(ctx.destination)

        // Set up fade out
        const startTime = this.duration - 2 // Start fade out 2 seconds before the end
        masterGain.gain.setValueAtTime(1, 0)
        masterGain.gain.setValueAtTime(1, startTime)
        masterGain.gain.linearRampToValueAtTime(0, this.duration)

        // Separate main and auxiliary tracks
        const mainTracks = tracks.filter(track => track.type === 'main')
        const auxTracks = tracks.filter(track => track.type === 'auxiliary')

        // Process main tracks sequentially
        if (mainTracks.length > 0) {
          // Get tracks in the correct order
          const tracksToPlay = this.playOrder === 'sequential'
            ? mainTracks
            : [...mainTracks].sort(() => Math.random() - 0.5)
          let currentTime = 0

          while (currentTime < this.duration) {
            for (const track of tracksToPlay) {
              // If adding this track would exceed the duration, break
              if (currentTime >= this.duration) break

              const source = ctx.createBufferSource()
              const trackGain = ctx.createGain()
              
              source.buffer = track.buffer
              source.playbackRate.value = 1.0 // 确保播放速率为1
              trackGain.gain.value = track.volume
              
              source.connect(trackGain)
              trackGain.connect(masterGain)
              
              // Start this track at the current time
              source.start(currentTime)
              currentTime += track.buffer.duration
            }
          }
        }

        // Process auxiliary tracks (each loops independently)
        for (const track of auxTracks) {
          const source = ctx.createBufferSource()
          const trackGain = ctx.createGain()
          
          source.buffer = track.buffer
          source.playbackRate.value = 1.0 // 确保播放速率为1
          trackGain.gain.value = track.volume
          
          source.connect(trackGain)
          trackGain.connect(masterGain)
          
          // Enable looping for auxiliary tracks
          source.loop = true
          source.loopEnd = track.buffer.duration
          
          source.start(0)
        }
        
        // Render the audio
        return await ctx.startRendering()
      } catch (error) {
        console.error('Audio processing error:', error)
        throw new Error('Failed to process audio: ' + error.message)
      }
    },

    async encodeAudioBuffer(audioBuffer, format) {
      // Implementation depends on the format
      // For now, just return WAV
      return await this.encodeWAV(audioBuffer)
    },

    async encodeWAV(audioBuffer) {
      // 使用原始采样率和位深度
      const numOfChan = audioBuffer.numberOfChannels
      const length = audioBuffer.length * numOfChan * 2 // 16-bit = 2 bytes
      const buffer = new ArrayBuffer(44 + length)
      const view = new DataView(buffer)
      const sampleRate = audioBuffer.sampleRate
      const blockAlign = numOfChan * 2
      const byteRate = sampleRate * blockAlign
      
      // Write WAV header
      const writeString = (view, offset, string) => {
        for (let i = 0; i < string.length; i++) {
          view.setUint8(offset + i, string.charCodeAt(i))
        }
      }
      
      // RIFF chunk descriptor
      writeString(view, 0, 'RIFF')
      view.setUint32(4, 36 + length, true)
      writeString(view, 8, 'WAVE')
      
      // FMT sub-chunk
      writeString(view, 12, 'fmt ')
      view.setUint32(16, 16, true) // subchunk1size (16 for PCM)
      view.setUint16(20, 1, true) // audio format (1 for PCM)
      view.setUint16(22, numOfChan, true) // NumChannels
      view.setUint32(24, sampleRate, true) // SampleRate
      view.setUint32(28, byteRate, true) // ByteRate
      view.setUint16(32, blockAlign, true) // BlockAlign
      view.setUint16(34, 16, true) // BitsPerSample
      
      // Data sub-chunk
      writeString(view, 36, 'data')
      view.setUint32(40, length, true)
      
      // Write audio data
      const offset = 44
      const channels = []
      for (let i = 0; i < numOfChan; i++) {
        channels.push(audioBuffer.getChannelData(i))
      }
      
      let index = 0
      const volume = 1
      for (let i = 0; i < audioBuffer.length; i++) {
        for (let channel = 0; channel < numOfChan; channel++) {
          const sample = channels[channel][i]
          // 保持始采样值，只做要的范围限制
          const sample16bit = Math.max(-1, Math.min(1, sample)) * 0x7FFF
          view.setInt16(offset + index, sample16bit, true)
          index += 2
        }
      }
      
      return new Blob([buffer], { type: 'audio/wav' })
    },

    startSynthesisProgress() {
      this.showSynthesisProgress = true
      this.synthesisProgress = 0
      this.synthesisMessage = 'Preparing audio files...'
      
      const messages = [
        'Analyzing audio...',
        'Processing tracks...',
        'Mixing audio...',
        'Optimizing output...',
        'Finishing up...'
      ]
      
      let messageIndex = 0
      this.synthesisTimer = setInterval(() => {
        if (this.synthesisProgress < 98) {
          // 非线性进度增加，让进度看起来更真实
          const increment = Math.max(1, 20 * Math.exp(-this.synthesisProgress / 30))
          this.synthesisProgress = Math.min(98, this.synthesisProgress + increment)
          
          // 根据进度更新消息
          if (this.synthesisProgress > messageIndex * 25 && messageIndex < messages.length) {
            this.synthesisMessage = messages[messageIndex]
            messageIndex++
          }
        }
      }, 200)
    },

    stopSynthesisProgress() {
      this.synthesisProgress = 100
      this.synthesisMessage = 'Synthesis Complete!'
      
      clearInterval(this.synthesisTimer)
      
      setTimeout(() => {
        this.showSynthesisProgress = false
        this.synthesisProgress = 0
        this.synthesisMessage = ''
      }, 1000)
    },

    startExportProgress() {
      let messageIndex = 0
      this.exportMessage = this.exportMessages[0]
      
      const updateMessage = () => {
        messageIndex = (messageIndex + 1) % this.exportMessages.length
        this.exportMessage = this.exportMessages[messageIndex]
      }

      // 每1.5秒更新一次提示息
      this.exportInterval = setInterval(updateMessage, 1500)
    },

    handleSeek(time) {
      if (!this.synthesizedBuffer) return
      
      // 先停止当前播放
      if (this.previewSource) {
        this.previewSource.stop()
        this.previewSource.disconnect()
      }
      
      // 更新当前时间
      this.currentTime = time
      
      if (!this.isPreviewPlaying) {
        // 如果当前没有播放，则开始播放
        this.handlePreview()
        return
      }
      
      // 创建新的音频源
      const audioContext = this.audioContext
      this.previewSource = audioContext.createBufferSource()
      this.previewSource.buffer = this.synthesizedBuffer
      
      // 创建增益节点
      const gainNode = audioContext.createGain()
      gainNode.gain.value = 1
      
      // 创建分析器节点
      this.analyserNode = audioContext.createAnalyser()
      
      this.previewSource.connect(gainNode)
      gainNode.connect(this.analyserNode)
      this.analyserNode.connect(audioContext.destination)
      
      // 从新位置开始播放
      this.previewStartTime = audioContext.currentTime - time
      this.previewSource.start(0, time)
      
      // 重新添加结束事件监听
      this.previewSource.onended = () => {
        this.stopPreview()
      }
    },

    updateTime() {
      if (!this.isPreviewPlaying) return
      this.currentTime = this.audioContext.currentTime - this.previewStartTime
      if (this.currentTime >= this.duration) {
        this.stopPreview()
        return
      }
      this.rafId = requestAnimationFrame(this.updateTime)
    },

    updatePlayOrder(value) {
      this.playOrder = value
      this.synthesizedBuffer = null // Reset synthesized buffer when order changes
    }
  },

  created() {
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
  },

  beforeDestroy() {
    this.stopPreview()
    if (this.audioContext) {
      this.audioContext.close()
    }
  }
}
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 
    'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1a1f36;
  background: linear-gradient(135deg, #0F172A, #1E293B);
  min-height: 100vh;
}

.el-header {
  background: rgba(30, 41, 59, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  color: #fff;
  text-align: left;
  padding: 0;
  width: 100%;
  z-index: 1000;
  height: auto !important;
  padding: 15px 0;
}

.header-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-content h1 {
  background: linear-gradient(135deg, #22D3EE, #818CF8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 32px;
  text-shadow: 0 0 20px rgba(34, 211, 238, 0.3);
  margin: 0;
  font-weight: 600;
  letter-spacing: -1px;
}

.header-subtitle {
  font-size: 14px;
  color: #94A3B8;
  margin-top: 4px;
  letter-spacing: 0.5px;
}

.el-main {
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  box-sizing: border-box;
}

/* Custom Element UI component styles */
:deep(.el-button--primary) {
  background: linear-gradient(135deg, #22D3EE, #818CF8);
  border-color: #22D3EE;
  font-weight: 500;
  box-shadow: 0 0 15px rgba(34, 211, 238, 0.3);
}

:deep(.el-button--primary:hover) {
  background: linear-gradient(135deg, #0EA5E9, #6366F1);
  border-color: #0EA5E9;
  transform: translateY(-1px);
  box-shadow: 0 0 20px rgba(34, 211, 238, 0.4);
}

:deep(.el-button--danger) {
  background: linear-gradient(135deg, #F43F5E, #FB7185);
  border-color: #F43F5E;
  font-weight: 500;
  box-shadow: 0 0 15px rgba(244, 63, 94, 0.3);
}

:deep(.el-button--danger:hover) {
  background: linear-gradient(135deg, #E11D48, #F43F5E);
  border-color: #E11D48;
  transform: translateY(-1px);
  box-shadow: 0 0 20px rgba(244, 63, 94, 0.4);
}

:deep(.el-slider__bar) {
  background: linear-gradient(90deg, #22D3EE, #818CF8);
  box-shadow: 0 0 10px rgba(34, 211, 238, 0.3);
}

:deep(.el-slider__button) {
  border-color: #22D3EE;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(34, 211, 238, 0.3);
}

:deep(.el-progress-bar__inner) {
  background: linear-gradient(135deg, #22D3EE, #818CF8);
  box-shadow: 0 0 10px rgba(34, 211, 238, 0.3);
}

/* Global text color adjustments */
:deep(.el-radio__label),
:deep(.el-slider__runway),
:deep(.el-input__inner),
:deep(.el-input-number__decrease),
:deep(.el-input-number__increase) {
  color: #94A3B8 !important;
}

:deep(.el-input__inner),
:deep(.el-input-number__decrease),
:deep(.el-input-number__increase) {
  background-color: rgba(15, 23, 42, 0.3) !important;
  border-color: rgba(148, 163, 184, 0.2) !important;
}

/* Background animation */
.background-animation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s ease;
  background: radial-gradient(circle at center, rgba(34, 211, 238, 0.1) 0%, transparent 70%);
}

.is-playing .background-animation {
  opacity: 1;
  animation: pulseWave 2s ease-in-out infinite;
}

@keyframes pulseWave {
  0% {
    transform: scale(0.95);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.5;
  }
}

.is-playing {
  background: linear-gradient(135deg, rgba(15, 23, 42, 0.95), rgba(30, 41, 59, 0.95));
}

.synthesis-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(10, 15, 30, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(8px);
}

.synthesis-modal {
  background: rgba(20, 25, 45, 0.95);
  border-radius: 12px;
  padding: 30px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.synthesis-content {
  text-align: center;
}

.synthesis-content h3 {
  color: #fff;
  margin: 0 0 20px;
  font-size: 1.2em;
  font-weight: 500;
}

.synthesis-content p {
  color: rgba(255, 255, 255, 0.7);
  margin: 15px 0 0;
  font-size: 0.9em;
}

.progress-bar {
  background: rgba(255, 255, 255, 0.1);
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
  margin: 20px 0;
}

.progress-fill {
  height: 100%;
  background: linear-gradient(90deg, #2a3f77 0%, #4a5fc9 100%);
  transition: width 0.3s ease;
  border-radius: 3px;
  position: relative;
}

.progress-fill::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.export-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(10, 15, 30, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.export-modal {
  background: rgba(20, 25, 45, 0.8);
  border-radius: 16px;
  padding: 40px;
  width: 90%;
  max-width: 440px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transform: translateY(0);
  animation: modalAppear 0.3s ease-out;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.export-content {
  text-align: center;
}

.export-icon {
  margin-bottom: 30px;
}

.circle-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: linear-gradient(135deg, #2a3f77, #4a5fc9);
  animation: bounce 0.5s ease-in-out infinite;
}

.circle:nth-child(2) {
  animation-delay: 0.1s;
}

.circle:nth-child(3) {
  animation-delay: 0.2s;
}

.export-content h3 {
  color: #fff;
  margin: 0 0 15px;
  font-size: 1.4em;
  font-weight: 500;
  text-shadow: 0 0 10px rgba(74, 95, 201, 0.3);
}

.export-message {
  color: rgba(255, 255, 255, 0.8);
  margin: 15px 0;
  font-size: 1em;
  animation: fadeInOut 1.5s ease-in-out infinite;
}

.warning-message {
  margin-top: 30px;
  padding: 15px;
  background: rgba(244, 63, 94, 0.1);
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.warning-message i {
  color: #f43f5e;
}

@keyframes modalAppear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

.user-guide {
  margin: 60px auto;
  padding: 0 40px 60px 40px;
  margin-bottom: 0px;
  max-width: 1360px;
  width: calc(100% - 80px);
  display: flex;
  justify-content: center;
}

.guide-content {
  background: rgba(30, 41, 59, 0.8);
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
}

.guide-content h2 {
  color: #fff;
  font-size: 24px;
  margin: 0 0 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
  background: linear-gradient(135deg, #22D3EE, #818CF8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.guide-section {
  margin-bottom: 30px;
}

.guide-section:last-child {
  margin-bottom: 0;
}

.guide-section h3 {
  color: #fff;
  font-size: 18px;
  margin: 0 0 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.guide-section h3::before {
  content: '';
  display: block;
  width: 4px;
  height: 18px;
  background: linear-gradient(to bottom, #22D3EE, #818CF8);
  border-radius: 2px;
}

.guide-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.guide-section li {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
  line-height: 1.6;
}

.guide-section li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #22D3EE;
}

@media (max-width: 768px) {
  .user-guide {
    margin: 40px auto;
    padding: 0 20px 40px 20px;
    width: calc(100% - 40px);
  }
  
  .guide-content {
    padding: 20px;
  }
  
  .guide-content h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .guide-section h3 {
    font-size: 16px;
  }
}

.upload-demo {
  margin-top: 10px;
}

.guide-section p {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  margin: 0;
}
</style> 